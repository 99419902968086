
import React from "react"
import ImgInvitatie from "./ImgInvitatie";
import mikey from '../images/mikey_sleep.png'
import "../css/ImgInvitatie.css"
import data from "../Data"
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MessageIcon from '@mui/icons-material/Message';
import { WhatsApp } from "@mui/icons-material";

const CardInvitatie = (props) => {
    return (

        <section id="invitatie" className="text-center mt-5" >
            <h1>Invitație</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4">
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">

                        <h5>
                            Când iubirea numai încape în 2 inimi se naște a 3-a! <br />
                            Și m-am născut eu, un îngeraș <br />
                            Cel mai iubit copilaș! <br />
                            Tati îmi spune că sunt mic <br />
                            Mami îmi dă să beau lăptic <br />
                        </h5>
                        <h5>Iar eu mă numesc,</h5>
                        <h4>Alfred Nicolas</h4>
                        <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                            <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                            </svg>
                            <br />
                        </h5>
                        <img  id="mikey_img" src={mikey} />
                        <h5>Nașii mă alintă tare, <br />
                            mă scot la plimbare. <br />
                            Vor să îmi facă o băiță <br />

                            în criștelniță, nu în cădiță. <br />
                            Dornic să vă cunosc, vă invit <br /> la prima mea petrecere pe care mami și tati:
                        </h5>
                        <h4>Edi și Cătălina</h4>
                        <h5>o dau in cinstea mea! <br /> Și la slujba Sfântului Botez, <br />
                            în brațe mă vor ține nașii mei dragi:
                        </h5>
                        <h4>Vasile și Mihaela</h4>

                        <br />
                        <h5>
                            Încreștinarea mea <br /> și prima mea sărbătoare, <br />
                            va avea loc:
                        </h5>
                        <h4 class="m-2">Vineri, 23 Decembrie 2022</h4>


                        <ImgInvitatie />

                        <h4 class="card-footer">Vă așteptăm cu drag!</h4>

                             
      {data.introData.map((item) => { 
        return(
            <>
                       {/** <a href="#forms" className="btn btn-primary">Confirmați Prezența</a>*/} 
                       Vom fi recunoscători dacă ați confirma prezența DVS! <br/>
                        Abia așteptăm să vă vedem! <br/>Dacă aveți orice altă întrebare,  vă rugăm să ne contactați sau
            scrieți-ne un mesaj:<br />
            {item.mama} & {item.tata} {item.tel} : <br /><a class=" m-2 btn_logo" href={item.phone}> <PhoneIphoneIcon /> Phone</a>
           {/*<a className="m-2 btn_logo" href={item.viber}> <PhoneIcon />Viber</a>*/} 
           <a className="m-2  btn_logo" href={item.whatsapp}><WhatsApp />WhatsApp</a>
            <a className=" m-2  btn_logo" href={item.messenger}><MessageIcon />Messenger</a><br />
     </>
     ) })}
            
            </div>




                </div>
            </div>
        </section>

    )
}

export default CardInvitatie;