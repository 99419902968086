import restaurant from "./images/gs1.jpeg";
import biserica from "./images/bis.jpeg";
import img_card from "./images/nicolas.jpg";
import imgheadermb from "./images/mekey_headerh_mb.jpg";
import imgheader from "./images/mekey_headerh.jpg";
import imgheadermiini from "./images/mikey.webp";
import imgheadermiinimb from "./images/headermb.jpg";
import logo from './images/mikey_sleep.png'



const data = {
    introData: [{
        copilul: "Alfred Nicolas",
        familia: "",
        logo: logo,
        tata: "Edi",
        mama: "Cătălina",
        data: "23 Decembrie 2022",
        data_confirmare: "1 Octombrie 2022",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "anusic007@gmail.com", 
       tel: "+49 176 24724796",
       phone: "tel:+4917624724796",
       viber: "viber://chat?number=%2B4915124454082",
       whatsapp: "https://wa.me/+4917624724796",
       messenger: "https://www.messenger.com/t/alfrededmundgeorg.schmidt",
       tel1: "+32 466 04 21 20",
       phone1: "tel:+32466042120",
       viber1: "viber://chat?number=%2B4915124454082",
       whatsapp1: "https://wa.me/+32466042120",
       messenger1: "https://www.messenger.com/t/dcristi.mihaila.566",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sf. Botez",
            localul: "Biserica",
            name: "Kirche Minfeld",
            data: "23 decembrie 2022, vineri, ora 15:00",
            adress: "Herrengasse 4-8, 76872 Minfeld, Germany",
            harta: "https://goo.gl/maps/hirBwUnmemeo7gWb9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10892.148700719732!2d28.7480613!3d46.9609596!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8a5a5f5d7adcc261!2sTesalia!5e0!3m2!1sro!2s!4v1648740787752!5m2!1sro!2s"
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurant",
            name: "La GS Mary",
            data: "23 decembrie 2022, vineri, ora 18:00",
            adress: "Kohlfahrtstraße 2, 76689 Karlsdorf-Neuthard, Germany",
            harta: "https://goo.gl/maps/md56aXikehRpCZzg7",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10004.597026805408!2d4.3916527!3d51.1794719!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x187684ce4ba5f054!2sBiserica%20Ortodox%C4%83%20Rom%C3%A2n%C4%83%20Antwerpen!5e0!3m2!1sro!2s!4v1658141325261!5m2!1sro!2s"
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Un copil este un dar al lui Dumnezeu, un motiv de a crede în minuni, este pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;